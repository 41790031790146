export class Header
{
	header = document.querySelector('[data-s~="Header"]')

    show()
    {
        this.header.style.transform = "translateY(0)"
    }

    hide()
    {
        this.header.style.transform = "translateY(-"+this.header.offsetHeight+"px)"
    }

    check()
    {
        const currentScroll = window.scrollY
        const isScrollingDown = currentScroll > this.lastScrollTop
        this.lastScrollTop = currentScroll <= 0 ? 0 : currentScroll

        if (window.scrollY > 200 && isScrollingDown) {
            this.hide()
        } else if (!isScrollingDown) {
            this.show()
        }
    }

    watch()
    {
        addEventListener("load", () => {
            this.check()
        })
        addEventListener("scroll", () => {
            this.check()
        })
        addEventListener("resize", () => {
            this.check()
        })
    }
}
